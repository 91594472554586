import {useRoute} from 'vue-router';
import {useDelete, useGet, usePost, usePut} from '@console/helpers/query';
import type {ApiResponse, Domain, PaginatedResponse} from '@/console';
import {computed} from 'vue';
import get from 'lodash/get';

export const useGetDomainList = () => {
  const route = useRoute();
  const {projectKey} = route.params;

  const response = useGet<PaginatedResponse<Domain>>(
    `/projects/${projectKey}/domains`
  );
  const domains = computed<Domain[]>(() =>
    get(response, 'data.value.data', [])
  );
  return {domains, ...response};
};

export const useGetDomain = () => {
  const route = useRoute();
  const {domainId} = route.params;

  const response = useGet<ApiResponse<Domain>>(`/domains/${domainId}`);
  const domain = computed(() => get(response, 'data.value.data', {} as Domain));
  return {domain, ...response};
};

export const useCreateDomain = () => {
  const route = useRoute();
  const {projectKey} = route.params;

  return usePost<
    PaginatedResponse<Domain>,
    {hostname: string; environment_id: string}
  >(`/projects/${projectKey}/domains`);
};

export const useUpdateDomain = () => {
  const route = useRoute();
  const {domainId} = route.params;

  return usePut<ApiResponse<Domain>, Partial<Domain>>(`/domains/${domainId}`);
};

export const useDeleteDomain = () => {
  return useDelete(`/domains`);
};
