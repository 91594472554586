<template>
  <div
    :class="{
      'w-full': fullwidth,
    }"
  >
    <div class="w-full relative">
      <component
        :is="computedComponent"
        :id="id"
        ref="input"
        v-maska="computedMask"
        :aria-label="ariaLabel"
        :autocapitalize="autocapitalize"
        :autocomplete="autocomplete"
        :autofocus="autofocus"
        :aria-required="required"
        :required="required"
        :class="[
          {
            'form-input block rounded-md text-sm': true,
            'w-full': fullwidth,
            'w-16': type === 'number',
            'is-invalid border-error': invalid,
            'border-input': !invalid && !disabled,
            'text-red-600': max && modelValue && max < modelValue.length,
            'bg-gray-100 dark:bg-gray-600/80 border-input text-black/70 dark:text-white':
              disabled || readonly,
            'cursor-not-allowed': disabled,
            'bg-white dark:bg-gray-700': !disabled && !readonly,
            'font-mono': monospace,
            'pr-9': showCopyButton,
          },
        ]"
        :cols="computedCols"
        :aria-describedby="computedDescribedBy"
        :disabled="disabled"
        :mask="mask"
        :max="max"
        :min="min"
        :name="name"
        :pattern="pattern"
        :placeholder="placeholder"
        :readonly="readonly"
        :rows="computedRows"
        :size="size"
        :spellcheck="spellcheck"
        :step="step"
        :type="computedType"
        :value="modelValue"
        v-bind="$attrs"
        @input="$emit('update:modelValue', $event.target.value)"
      >
        <template v-if="type === 'textarea'">
          {{ modelValue }}
        </template>
      </component>

      <template v-if="showCopyButton">
        <div
          class="absolute top-0 right-0 bottom-0 p-1 border border-transparent"
        >
          <CopyBtn :value="modelValue ? modelValue.toString() : ''" />
        </div>
      </template>

      <p
        v-if="max"
        class="max"
        :class="{
          'text-light': remainingChars >= 20,
          'text-yellow-800 dark:text-yellow-200':
            remainingChars < 20 && remainingChars >= 0,
          'text-red-800 dark:text-red-200': remainingChars < 0,
        }"
      >
        <small>{{ max - remainingChars }}/{{ max }}</small>
      </p>
    </div>
  </div>
</template>

<script>
  import {maska} from 'maska';
  import Btn from '@/common/ui/components/Btn.vue';
  import {useClipboard} from '@vueuse/core';
  import Icon from '@/common/ui/components/Icon.vue';
  import CopyBtn from '@console/components/CopyBtn.vue';

  export default {
    components: {CopyBtn, Icon, Btn},
    directives: {
      maska,
    },
    inheritAttrs: false,

    props: {
      autocapitalize: {
        type: Boolean,
        default: false,
      },
      autocomplete: {
        type: String,
        default: 'on',
      },
      autofocus: {
        type: Boolean,
        default: false,
      },
      cols: {
        type: Number,
        default: null,
      },
      describedBy: {
        type: Array,
        default: () => [],
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      fullwidth: {
        type: Boolean,
        default: true,
      },
      invalid: {
        type: Boolean,
        default: false,
      },
      id: {
        type: String,
        default: function () {
          return 'c-textbox-id-' + Math.random().toString(36).substr(2, 9);
        },
      },
      mask: {
        type: [String, Object],
        default: '',
      },
      max: {
        type: [Number, String],
        default: null,
      },
      min: {
        type: [Number, String],
        default: null,
      },
      name: {
        type: String,
        default: null,
      },
      pattern: {
        type: String,
        default: null,
      },
      placeholder: {
        type: String,
        default: null,
      },
      readonly: {
        type: Boolean,
        default: false,
      },
      rows: {
        type: [Number, String],
        default: null,
      },
      size: {
        type: [Number, String],
        default: 20,
      },
      spellcheck: {
        type: Boolean,
        default: false,
      },
      step: {
        type: [Number, String],
        default: null,
      },
      type: {
        type: String,
        default: 'text',
      },
      modelValue: {
        type: [String, Number, undefined],
        default: '',
      },
      monospace: {
        type: Boolean,
        default: false,
      },
      allowCopy: {
        type: Boolean,
        default: false,
      },
      ariaLabel: {
        type: String,
        default: null,
      },
      required: {
        type: Boolean,
        default: false,
      },
    },

    emits: ['update:modelValue'],

    setup() {
      const {copy, copied, isSupported} = useClipboard();

      return {
        copy,
        copied,
        isSupported,
      };
    },

    computed: {
      showCopyButton() {
        return this.readonly && this.allowCopy && this.isSupported;
      },

      remainingChars() {
        if (this.max) {
          return this.max - (this.modelValue ? this.modelValue.length : 0);
        }

        return null;
      },

      computedComponent() {
        if (this.type === 'textarea') {
          return 'textarea';
        }

        return 'input';
      },

      computedDescribedBy() {
        if (this.describedBy.length > 0) {
          return this.describedBy.join(' ');
        }

        return null;
      },

      computedType() {
        if (this.type === 'textarea') {
          return null;
        }

        return this.type;
      },

      computedCols() {
        if (this.type !== 'textarea') {
          return null;
        }

        return this.cols;
      },

      computedRows() {
        if (this.type !== 'textarea') {
          return null;
        }

        return this.rows ? this.rows : 4;
      },

      computedMask() {
        if (!this.mask) {
          return null;
        }

        return {
          mask: this.mask,
          tokens: {
            x: {pattern: /[0-9a-zA-Z]/, uppercase: true},
          },
        };
      },
    },

    methods: {
      focus() {
        this.$refs.input.focus();
      },
    },
  };
</script>
